import React from 'react';

import { Link } from 'react-router-dom';

import image404 from '../images/404.svg';
import '../css/notFound404.css';
import SEO from '../components/SEO';

function NotFound404() {
  return (
    <>
      <SEO
        title="WE3 Academy | 404 | Coding Bootcamp"
        description="At WE3 Academy, we are bridging the gap between education and employability with our in-person training courses designed to provide you with fundamental skills and real-world experiences to accelerate your career."
        keywords="WE3 Academy, WE3, Coding Bootcamp, Coding academy, coding school, web development bootcamp, education, education center, educational institute, best coding bootcamp, coding bootcamp in India, coding bootcamp in mumbai, best coding bootcamp in mumbai, code school, programming bootcamp, full stack web development course, full stack web development, dev bootcamp, code bootcamp,  career courses, digital courses, course, courses, career development, career development course, social media marketing, social media marketing course, social media, best courses in mumbai, social media courses in mumbai, career development courses in mumbai, part time courses, part time course, full time courses, computer programming, computer coding, training courses, training, in-person training, real world experiences, real work environment, employability, training institute, training bootcamp, training center"
      ></SEO>
      <div className="notFound404">
        <img src={image404} alt="404 NOT FOUND" />
        <p>Not Found</p>
        <p>
          Oops! We couldn’t find the page you were looking for.{' '}
          <Link to="/">Click here</Link> to go back to the homepage
        </p>
      </div>
    </>
  );
}

export default NotFound404;
