import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/database'; // If using Firebase database

var config = {
    apiKey: "AIzaSyDMVYVADqq5aACsRD-bf8x_qCjc7q1-SiQ",
    authDomain: "we3-academy.firebaseapp.com",
    databaseURL: "https://we3-academy.firebaseio.com",
    projectId: "we3-academy",
    storageBucket: "we3-academy.appspot.com",
    messagingSenderId: "239792312617"
};
;

export const configType = 'dev';
// export const configType = 'live';

export const firebaseApp = firebase.initializeApp(config);
export const db = firebaseApp.firestore();
