import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserLock } from '@fortawesome/free-solid-svg-icons'

import mainLogo from '../images/main.png';

let Locked = (props) => {
    const [password, setPassword] = useState("");
    const submitForm = () => props.checkLocked(password).then(val => setPassword(""))
    return (
        <div style={{ width: "calc(100vw-100px)", height: "calc( 100vh - 110px )", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", padding: "50px"}}>
            <img src={mainLogo} alt="WE3 Academy" />
            <p>Hello, our website is coming soon.</p>
            <br />
            <form className="lockedForm" onSubmit={submitForm} style={{marginTop: "50px", textAlign: "center"}}>
                <FontAwesomeIcon icon={faUserLock} style={{ fontSize: "5em", color: "#eaeaea" }} />
                <p style={{marginTop: "50px", marginBottom: "20px",}}>If you have the password, you can take a peek at the progress</p>
                <input value={password} onChange={(e) => Promise.resolve(setPassword(e.target.value)).then(() => props.setLocked("true"))} type="password" placeholder="Password" style={{ padding: "10px", display: "inline", marginRight: "20px" }} />
                <button type="submit" style={{display: "inline"}} className="btn btn-outline btn-outline-yellow">Enter</button>
            </form>
            {
                props.error &&
                <p style={{color: "red", fontStyle: "italic"}}>The password you entered is incorrect</p>            
            }
        </div>
    )
}

export default Locked