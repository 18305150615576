import React from 'react';
import { Link, NavLink, withRouter } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBars,
  faTimes,
  faChevronUp,
  faChevronDown
} from '@fortawesome/free-solid-svg-icons';

import '../css/header.css';
import logo from '../images/main.png';

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapse: true,
      courseActive: false,
      aboutActive: false
    };
    this.toggleMenu = this.toggleMenu.bind(this);
    this.checkActiveLinks = this.checkActiveLinks.bind(this);
  }

  componentDidMount() {
    this.checkActiveLinks();
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (
      this.props.location &&
      this.props.location.pathname &&
      prevProps.location &&
      prevProps.location.pathname &&
      this.props.location.pathname !== prevProps.location.pathname
    ) {
      this.checkActiveLinks();
    }
  }

  toggleMenu(collapse) {
    this.setState({ collapse });
  }

  checkActiveLinks() {
    if (this.props.location && this.props.location.pathname) {
      // let aboutLinks = [ '/company', '/process', '/work-with-us', '/career-services', 'blog' ],
      let aboutLinks = [
          '/company',
          '/process',
          '/work-with-us',
          '/career-services'
        ],
        courseLinks = [
          '/full-time/web-dev',
          '/full-time/design',
          '/part-time/web-dev',
          '/part-time/design'
        ];
      this.setState({
        courseActive: courseLinks.includes(this.props.location.pathname),
        aboutActive: aboutLinks.includes(this.props.location.pathname)
      });
    }
    return null;
  }

  render() {
    const { history, location } = this.props,
      { collapse, courseActive, aboutActive } = this.state;
    const isHome = location?.pathname === '/';
    return (
      <header id="header">
        <div id="logo">
          <Link to="/">
            <img src={logo} alt="WE3 Academy" />
          </Link>
          <div className="mobile-menu">
            <button
              aria-label="Toggle Menu"
              onClick={() => this.toggleMenu(!collapse)}
            >
              {collapse ? (
                <FontAwesomeIcon icon={faBars} />
              ) : (
                <FontAwesomeIcon icon={faTimes} />
              )}
            </button>
          </div>
        </div>
        <nav className={collapse ? 'nav nav-collapsed' : 'nav nav-expanded'}>
          <ul className="nav__menu">
            {!isHome && (
              <li className="nav__menu-item">
                <Link to="/">Home</Link>
              </li>
            )}
            <li className="nav__menu-item">
              <span>
                <a
                  href="/home#courses"
                  className={courseActive ? 'active' : ''}
                >
                  Courses
                </a>
                <FontAwesomeIcon
                  className="nav__menu-arrowUp"
                  icon={faChevronUp}
                />
                <FontAwesomeIcon
                  className="nav__menu-arrowDown"
                  icon={faChevronDown}
                />
                <ul className="nav__submenu">
                  <li className="nav__submenu-item nav__submenu-section">
                    Part-Time Courses
                  </li>
                  <li className="nav__submenu-item">
                    <NavLink to="/career-development-course">
                      Career Development
                    </NavLink>
                  </li>
                  <li className="nav__submenu-item">
                    <NavLink to="/social-media-marketing-course">
                      Social Media Marketing
                    </NavLink>
                  </li>
                  <li className="nav__submenu-item">
                    <NavLink to="/part-time/web-dev">
                      Web Development{' '}
                      <span className="small-text tag uppercase">
                        coming soon
                      </span>
                    </NavLink>
                  </li>
                  {/* <li className="nav__submenu-item"><NavLink to="/part-time/design">UI / UX Design <span className="small-text tag uppercase">coming soon</span></NavLink></li> */}
                  <li className="nav__submenu-item nav__submenu-section">
                    Full-Time Courses
                  </li>
                  <li className="nav__submenu-item">
                    <NavLink to="/full-time/web-dev">
                      Web Development{' '}
                      <span className="small-text tag uppercase">
                        coming soon
                      </span>
                    </NavLink>
                  </li>
                  {/* <li className="nav__submenu-item"><NavLink to="/full-time/design">UI / UX Design <span className="small-text tag uppercase">coming soon</span></NavLink></li> */}
                </ul>
              </span>
            </li>
            <li className="nav__menu-item">
              <span>
                <a href="/home#about" className={aboutActive ? 'active' : ''}>
                  About
                </a>{' '}
                <FontAwesomeIcon
                  className="nav__menu-arrowUp"
                  icon={faChevronUp}
                />
                <FontAwesomeIcon
                  className="nav__menu-arrowDown"
                  icon={faChevronDown}
                />
                <ul className="nav__submenu">
                  <li className="nav__submenu-item">
                    <NavLink to="/company">Company</NavLink>
                  </li>
                  <li className="nav__submenu-item">
                    <NavLink to="/hire">Hire</NavLink>
                  </li>
                  <li className="nav__submenu-item">
                    <NavLink to="/process">How We Work</NavLink>
                  </li>
                  <li className="nav__submenu-item">
                    <NavLink to="/work-with-us">Work With Us</NavLink>
                  </li>
                  <li className="nav__submenu-item">
                    <NavLink to="/career-services">Career Services</NavLink>
                  </li>
                  <li className="nav__submenu-item">
                    <NavLink to="/faq">FAQ</NavLink>
                  </li>
                </ul>
              </span>
            </li>
            <li className="nav__menu-item">
              <NavLink to="/workshop">Workshops</NavLink>
            </li>
            <li className="nav__menu-item">
              <NavLink to="/blog">Blog</NavLink>
            </li>
            <li className="nav__menu-item">
              <NavLink to="/contact">Contact</NavLink>
            </li>
            <li className="nav__menu-item">
              <button
                type="button"
                className="btn btn-outline btn-outline-yellow"
                onClick={() => {
                  history.push('/apply');
                }}
              >
                Register Now
              </button>
            </li>
          </ul>
        </nav>
      </header>
    );
  }
}

export default withRouter(Header);
