import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import '../css/footer.css';

import logoWhite from '../images/logoWhite.png';
import SocialIcons from '../components/SocialIcons';
class Footer extends Component {
  render() {
    return (
      <footer id="footer" className="breakout">
        <div className="container">
          <div className="footer__flex">
            <div className="footer__flex-item">
              <img src={logoWhite} alt="WE3 Academy" />
              <p className="tagline small-text">
                Bridging the gap between education and employability
              </p>
            </div>
            <ul className="footer__flex-item footer__menu">
              <li className="footer__menu-heading">About</li>
              <li>
                <Link to="/company">Company</Link>
              </li>
              <li>
                <Link to="/process">How we Work</Link>
              </li>
              {/* <li><Link to="/blog">Blog</Link></li> */}
              <li>
                <Link to="/faq">FAQ</Link>
              </li>
              <li>
                <Link to="/workshop">Workshop</Link>
              </li>
            </ul>
            <ul className="footer__flex-item footer__menu">
              <li className="footer__menu-heading">Work With Us</li>
              <li>
                <Link to="/hire">Hire Our Students</Link>
              </li>
              <li>
                <Link to="/work-with-us#teach">Mentorship Opportunities</Link>
              </li>
              <li>
                <Link to="/work-with-us#teach">Careers at WE3</Link>
              </li>
              <li>
                <Link to="/work-with-us#partner">Partner With Us</Link>
              </li>
            </ul>
            <ul className="footer__flex-item footer__menu">
              <li className="footer__menu-heading">Courses</li>
              <li>
                <Link to="/career-development-course">
                  Career Development Course
                </Link>
              </li>
              <li>
                <Link to="/social-media-marketing-course">
                  Social Media Marketing Course
                </Link>
              </li>
              <li>
                <Link to="/part-time/web-dev">Web Development Part Time</Link>
              </li>
              <li>
                <Link to="/full-time/web-dev">Web Development Full Time</Link>
              </li>
              {/* <li><Link to="/full-time/design">UI / UX Design Full Time</Link></li> */}
              {/* <li><Link to="/part-time/design">UI / UX Design Part Time</Link></li> */}
            </ul>
          </div>

          <div id="footer__bottom">
            <div id="footer__bottom-social">
              <SocialIcons
                justifyContent="center"
                customStyles={{ marginRight: '10px' }}
              />
            </div>
            <p id="copyright" className="small-text">
              &copy; 2019 WE3 Academy | Designed &amp; Developed By&nbsp;
              <a
                href="https://we3solutions.in"
                target="_blank"
                rel="noopener noreferrer"
              >
                WE3 Solutions India
              </a>
              &nbsp; | <Link to="/terms-of-service">Terms of Service</Link>
              &nbsp; |&nbsp;
              <Link to="/privacy-policy">Privacy Policy</Link>
            </p>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
