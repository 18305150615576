import { createStore, applyMiddleware, compose } from 'redux';
import ReduxPromise from 'redux-promise';
import thunk from "redux-thunk"
import rootReducer from "./reducers/index"
import { configType } from './utils/firebase';

const middleware = [thunk, ReduxPromise];

// #deploy #todo -> comment next line, uncomment line after that
// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const composeEnhancers = configType && configType === 'dev' ?  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose : compose;

const store = createStore(rootReducer, composeEnhancers(
  applyMiddleware(...middleware)
));

export default store;