import React, { useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import Locked from './Locked';
import Header from './Header';
import Body from './Body';
import Footer from './Footer';

import '../css/app.css';
const App = () => {
  // const [ locked, setLocked ] = useState("true");
  const [locked, setLocked] = useState(false);

  const checkLocked = (val) =>
    Promise.resolve(val === 'We3_2048' ? setLocked(false) : setLocked('error'));
  return (
    <Router>
      <div className="app">
        {locked ? (
          <Locked
            checkLocked={checkLocked}
            setLocked={setLocked}
            error={locked && locked === 'error' ? true : false}
          />
        ) : (
          <div className="container">
            <Header />
            <Body />
            <Footer />
          </div>
        )}
      </div>
    </Router>
  );
};

export default App;
