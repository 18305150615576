import React from 'react';
import {
  faFacebook,
  faTwitter,
  // faGithub,
  faInstagram,
  // faBehance,
  faYoutube,
  faLinkedin,
  faPinterest
} from '@fortawesome/free-brands-svg-icons';

// HOME PAGE
import introHomeImage from '../images/home/home.svg';
import careerServices from '../images/home/careerServices.svg';
import learnByDoing from '../images/home/learnByDoing.svg';
import mentorshipAndSupport from '../images/home/mentorshipAndSupport.svg';
import structuredCourses from '../images/home/structuredCourses.svg';

// HOME PAGE IMAGES
import codeScreen from '../images/home/ImageBlock/codescreen.webp';
import girlCoding from '../images/home/ImageBlock/coding.webp';
import studentBootcampCoding from '../images/home/ImageBlock/developer.webp';
import postitNotesPM from '../images/home/ImageBlock/postit.webp';

// WEB DEV COURSES TECH ICONS
import html5 from '../images/courses/webdev/html5.png';
import css3 from '../images/courses/webdev/css3.png';
import js from '../images/courses/webdev/js.png';
import vscode from '../images/courses/webdev/vscode.png';
import postman from '../images/courses/webdev/postman.png';
import react from '../images/courses/webdev/react.png';
import sass from '../images/courses/webdev/sass.png';
import nodejs from '../images/courses/webdev/nodejs.png';
// import mongodb from '../images/courses/webdev/mongodb.png'
import webpack from '../images/courses/webdev/webpack.png';
import redux from '../images/courses/webdev/redux.png';
import git from '../images/courses/webdev/git.png';
import npm from '../images/courses/webdev/npm.png';
import github from '../images/courses/webdev/github.png';
import socket from '../images/courses/webdev/socket-io.png';

// SMM COURSE TECH ICONS
import instagram from '../images/courses/smm/instagram.png';
import facebook from '../images/courses/smm/facebook.png';
import twitter from '../images/courses/smm/twitter.png';
import linkedin from '../images/courses/smm/linkedin.png';
import hootsuite from '../images/courses/smm/hootsuite.png';
import buffer from '../images/courses/smm/buffer.png';
import trello from '../images/courses/smm/trello.png';
import meetup from '../images/courses/smm/meetup.png';
import canva from '../images/courses/smm/canva.png';
import unsplash from '../images/courses/smm/unsplash.png';
// import pixabay from "../images/courses/smm/pixabay.png";
// import freepik from "../images/courses/smm/freepik.png";

// COURSE ILLUSTRATIONS
import webDevImg from '../images/courses/webdev.png';
import smmImg from '../images/courses/smm.png';
import careerDevImg from '../images/courses/careerDev.png';

// COURSE ICONS PROGRESSION
import curriculum1 from '../images/courses/curriculum/1.png';
import curriculum2 from '../images/courses/curriculum/2.png';
import curriculum3 from '../images/courses/curriculum/3.png';
import curriculum4 from '../images/courses/curriculum/4.png';
import curriculum5 from '../images/courses/curriculum/5.png';

// HIRE
import introHireImg from '../images/hire/hire.png';
import hireCareerServices from '../images/hire/careerServices.png';
import hireCuratedCandidates from '../images/hire/curatedCandidates.png';
import hirePassionAndMotivation from '../images/hire/passionAndMotivation.png';
import hireQualityCodingSkills from '../images/hire/qualityCodingSkills.png';

// COMPANY
import introCompanyImg from '../images/company/company.png';
import companyCommittedToQuality from '../images/company/committedToQuality.png';
import companyFocussedOnResults from '../images/company/focussedOnResults.png';
import companyDrivenByPassion from '../images/company/drivenByPassion.png';
import companyAdaptableToChange from '../images/company/adaptableToChange.png';

// HOW WE WORK
import introHowWeWorkImg from '../images/howWeWork/howWeWork.png';
import howWeWork1 from '../images/howWeWork/01.png';
import howWeWork2 from '../images/howWeWork/02.png';
import howWeWork3 from '../images/howWeWork/03.png';
import howWeWork4 from '../images/howWeWork/04.png';

// WORK WITH Us
import introWorkWithUsImg from '../images/workWithUs/workWithUs.png';

// CAREER SERVICES
import introCareerServices from '../images/careerServices/careerServices.png';
import careerCareerGuidance from '../images/careerServices/careerGuidance.png';
import careerJobSearchPreparation from '../images/careerServices/jobSearchPreparation.png';
import careerNetworking from '../images/careerServices/networking.png';
import careerOnGoingSupport from '../images/careerServices/ongoingSupport.png';

const _homeData = {
  intro: {
    heading: 'Learn to Code',
    text: [
      'Intensive courses in web application development and UI / UX design to accelerate your career. Checkout our courses now.'
    ],
    img: introHomeImage,
    cta: 'View Courses',
    link: '#courses'
  },
  reasoning: {
    heading: 'Why learn at WE3 Academy?',
    grid: [
      {
        heading: 'Learn By Doing',
        image: learnByDoing,
        text: [
          'Learn by building small projects using tools you’ll encounter in a real work environment.'
        ]
      },
      {
        heading: 'Mentorship & Support',
        image: mentorshipAndSupport,
        text: [
          'Mentors and instructors are trained professionals who have worked in the industry you aim to be in.'
        ]
      },
      {
        heading: 'Structured Courses',
        image: structuredCourses,
        text: [
          'Our courses have weekly & daily schedules. Defined structure brings accountability and provides focus.'
        ]
      },
      {
        heading: 'Career Services',
        image: careerServices,
        text: [
          'We are with you during and after your course to support you to get the job you deserve.'
        ]
      }
    ]
  },
  courses: {
    heading: 'Courses',
    text: [
      'Our courses are designed to provide you with fundamental skills and real-world experiences to accelerate your career.'
    ],
    types: [
      {
        heading: 'Career Development Course',
        text: [
          'Build an effective portfolio, present your skills efficiently & network effectively. With mock interviews and constant feedback, this course is designed to improve your communication & presentation skills. 1-on-1 Interview and Review sessions will help build your confidence and allow you to interview better.'
        ],
        subType: [
          {
            heading: 'Part-Time Course',
            details:
              'Monday, Wednesday & Friday from 2PM - 5PM. Ten 3 hour sessions. Course ends with a presentation & networking Saturday.',
            duration: '3 weeks',
            duarionHours: '25+ Hours',
            extra: 'MONDAY - WEDNESDAY - FRIDAY ( 2PM - 5PM )',
            cta: 'See Details',
            link: '/career-development-course'
          }
        ]
      },
      {
        heading: 'Social Media Marketing Course',
        text: [
          'Master the art of social media marketing in 5 weekends with our part time in-person training in Mumbai, India. Social media marketing course at WE3 Academy is designed to give you hands-on practical experience of running and managing social media accounts for businesses.'
        ],
        subType: [
          {
            heading: 'Part-Time Course',
            details:
              '5 Weekends, Nine 4 hours sessions. Course ends with a presentation & networking Saturday.',
            duration: '5 Weekends',
            duarionHours: '36+ Hours',
            extra: 'SATURDAYS & SUNDAYS ( 2PM - 6PM )',
            cta: 'See Details',
            link: '/social-media-marketing-course'
          }
        ]
      },
      {
        heading: 'Full Stack Web Development',
        text: [
          'Learn the fundamentals of frontend and backend web development and to think like a developer. The course covers HTML5, CSS3, SASS, JavaScript, ES6, React, Redux, MongoDB, Firebase, Node JS, Express, Git, Test driven development, Agile methodologies and building projects from scratch; all taught by developers.'
        ],
        subType: [
          {
            heading: 'Part-Time Course',
            details:
              'Weekdays from 6:30pm - 9:30pm and Saturday from 10am to 5pm',
            duration: '24 weeks',
            duarionHours: '480+ Hours',
            extra: 'EVENINGS & WEEKENDS',
            cta: 'See Details',
            link: '/part-time/web-dev'
          },
          {
            heading: 'Full-Time Bootcamp',
            details:
              'Monday-Friday from 9am - 6pm. Immersive, intense and hands-on full-time bootcamp',
            duration: '16 weeks',
            duarionHours: '480+ Hours',
            extra: 'MONDAY - FRIDAY ( 9AM - 6PM )',
            cta: 'See Details',
            link: '/full-time/web-dev'
          }
        ]
      }
      // {
      //     heading: "UI / UX Design",
      //     text: ["Learn the fundamentals of design, color theory, best practices and current tools used to design products and web applications. Master how to research, design, and build for modern web applications. The course is structure to learn by doing and facilitated by the constant support and feedback from instructors and mentors."],
      //     subType: [
      //         {
      //             heading: "Full-Time Bootcamp",
      //             details: "Monday-Friday from 9am - 6pm. Immersive, intense and hands-on full-time bootcamp",
      //             duration: "16 weeks",
      //             extra: "MONDAY - FRIDAY ( 9AM - 6PM )",
      //             cta: "See Details",
      //             link: "/full-time/design"
      //         },
      //         {
      //             heading: "Part-Time Course",
      //             details: "Weekdays from 6:30pm - 9:30pm and Saturday from 10am to 5pm",
      //             duration: "24 weeks",
      //             extra: "EVENINGS & WEEKENDS",
      //             cta: "See Details",
      //             link: "/part-time/design"
      //         }
      //     ]
      // }
    ]
  },
  about: {
    heading: 'What is WE3 Academy?',
    text: [
      'We are one of the few of Mumbai’s coding bootcamp offering full-time and part-time courses in the latest full-stack web development and social media marketing. Our accelerated learning model provides an immersive experience for those looking to learn how to code or make careers as developers. ',
      'Learning happens best when facilitated with the right knowledge and delivered by the right people. We believe that in the right environment,  you can do anything.'
    ]
  },
  imageBlock: [
    { src: girlCoding, alt: 'we3academy-women-in-tech' },
    { src: codeScreen, alt: 'we3academy-codescreen' },
    { src: studentBootcampCoding, alt: 'we3academy-student-Bootcamp' },
    { src: postitNotesPM, alt: 'we3academy-postit-note-project-management' }
  ]
};

const _ctaBlock = {
  style1: {
    heading: 'Ready to take the next step?',
    text: [
      'To learn more about our courses and our way of teaching, email or speak with our education advisor today.'
    ],
    cta: [{ type: 'email' }, { type: 'call' }]
  },
  style2: {
    heading: 'Want to see WE3 Academy before you join?',
    text: [
      'Coming to see our space is a great way to experience WE3 Academy first-hand before applying to one of our courses. Click the button below to set up a personal tour! You’re gonna love it here.'
    ],
    cta: [
      {
        type: 'tour',
        customClass:
          'btn btn-outline btn-outline-blue margin-right-15 margin-top-5'
      },
      {
        type: 'viewCourses',
        customClass: 'btn btn-filled btn-filled-blue margin-top-5'
      }
    ]
  },
  // style3: {
  //     heading: "Passionate about UI / UX? Interested in mentoring or teaching?",
  //     text: ["If you are interested in becoming a full-time instructor at WE3 Academy for deisgn or want to help mentor students, feel free to get in touch and let's build an amazing community together."],
  //     cta: [{ type: 'apply', customLink: "/apply/mentor", customClass: "btn btn-outline btn-outline-blue margin-right-15 margin-top-5", customText: "Apply to Be A Mentor"},
  //           { type: 'apply', customLink: "/apply/instructor", customClass: "btn btn-filled btn-filled-blue margin-top-5", customText: "Apply to Be an Instructor"}]
  // },
  style4: {
    heading: 'Want to learn more about our courses?',
    text: [
      'To learn more about our courses and our way of teaching, email or speak with our education advisor today.'
    ],
    cta: [{ type: 'email' }, { type: 'call' }]
  },
  style5: {
    heading: 'Checkout our courses',
    text: [
      'View our courses to find out about curriculum, fees, dates and other important details. Or if you know which course you want to go ahead with, register now.'
    ],
    cta: [
      {
        type: 'apply',
        customClass:
          'btn btn-outline btn-outline-blue margin-right-15 margin-top-5'
      },
      {
        type: 'viewCourses',
        customClass: 'btn btn-filled btn-filled-blue margin-top-5'
      }
    ]
  },
  style6: {
    heading: 'Want to hire our graduates / sponsor a student?',
    text: [
      'Email or give us a call if you like what we’re doing and want to be a part of the change. Let’s discuss how we can help each other.'
    ],
    cta: [{ type: 'email' }, { type: 'call' }]
  },
  style7: {
    heading: 'Couldn’t find what you were looking for?',
    text: [
      'We’d love to hear from you to chat about how you might fit into our team!'
    ],
    cta: [{ type: 'email' }, { type: 'call' }]
  },
  style8: {
    heading: 'Want to speak at our events?',
    text: [
      'Want to conduct an online workshop (paid or free)? Get in touch with us your pitch. Your voice would be a critical addition to our platform'
    ],
    cta: [{ type: 'email' }, { type: 'call' }]
  },
  style9: {
    heading: 'Want to conduct a webinar / workshop?',
    text: [
      'Want to conduct an online workshop (paid or free)? Get in touch with us your pitch. Your voice would be a critical addition to our platform'
    ],
    cta: [{ type: 'email' }, { type: 'call' }]
  }
};

const _socialBlock = {
  style1: {
    text: [
      'Our mission is to bridge the gap between education and employability. We’re creating a community of developers, designers and entrepreneurs.',
      'Feel free to reach out and connect with us on social media.'
    ]
  }
};

const _socialLinks = [
  {
    name: 'Instagram',
    icon: faInstagram,
    link: 'https://instagram.com/we3academy'
  },
  {
    name: 'Twitter',
    icon: faTwitter,
    link: 'https://twitter.com/we3academy/'
  },
  {
    name: 'LinkedIn',
    icon: faLinkedin,
    link: 'https://www.linkedin.com/company/we3academy'
  },
  {
    name: 'Facebook',
    icon: faFacebook,
    link: 'http://facebook.com/we3academy'
  },
  // {
  //     icon: faGithub,
  //     link: "https://github.com/orgs/we3-academy/"
  // },

  // {
  //     icon: faBehance,
  //     link: "https://www.behance.net/we3academy"
  // },
  {
    name: 'Pinterest',
    icon: faPinterest,
    link: 'https://in.pinterest.com/we3academy/'
  },
  {
    name: 'YouTube',
    icon: faYoutube,
    link: 'https://www.youtube.com/channel/UCEfgFRQCzEshUQr_eQ4d3hw'
  }
];

const _webDevData = (type) => ({
  heading: 'Full-Stack Web Development Bootcamp',
  img: webDevImg,
  text: [
    `Become a Full Stack Web Developer in ${
      type === 'full' ? '16' : '24'
    } weeks with our ${type}-time in-person training in Mumbai, India.`
  ],
  type: `${type.charAt(0).toUpperCase() + type.slice(1)} - Time`,
  // impInfo: "// NEXT CLASS STARTS MAY 1ST // DEADLINE TO APPLY APRIL 15TH.",
  impInfo: '// COMING SOON',
  details: {
    Overview: {
      link: 'overview',
      sections: [
        {
          heading: 'Overview',
          text: [
            <span>
              The goal of our{' '}
              <a
                href="https://we3academy.com/blog/development/what-are-coding-bootcamps"
                target="_blank"
                rel="noreferrer noopener"
              >
                Full Stack Web Development Bootcamp
              </a>{' '}
              is to transform you into a professional developer. You’ll come to
              understand coding logic, algorithms, using popular languages and
              frameworks like JavaScript, React JS, Node JS, while learning how
              to think like a programmer by building web applications from the
              ground up.
            </span>,
            <span>
              <a href="#curriculum">This bootcamp</a> will provide you with
              mentorship and a conducive learning environment that will help
              launch your career as a web developer.
            </span>
          ],
          features: [
            { caption: '40 Hrs / Week' },
            { caption: `${type === 'full' ? '16' : '24'} Weeks` },
            { caption: 'Mentor-guided' }
          ]
        },
        {
          heading: 'Who is this Bootcamp For?',
          details: [
            {
              heading: 'Recent Graduates / College Students',
              text: [
                'Just graduated from junior college or with a bachelors degree? We’ve identified a big gap between the education and employability in India. This course will give you the fundamental real-world skills to launch a career as a web developer or start your own business if you have an idea you want to execute.'
              ]
            },
            {
              heading: 'Working Professionals',
              text: [
                'Working IT professionals / developers who want to upgrade their skills or switch career paths can also apply for the course. This course will help enhance your skills and enable you to fulfill your career goals. If you are currently working somewhere, you can opt for our part time course.'
              ]
            },
            {
              heading: 'Entrepreneurs',
              text: [
                'If you’re thinking of launching a tech company or have an idea that you want to look for investors for, this course will help you acquire the technical skills required to build an MVP or better manage a technical team in the future. You’ll also end up with great contacts and developer friends whom you could start a firm with.'
              ]
            }
          ]
        },
        {
          heading: 'What will you learn?',
          icons: [
            {
              icon: html5,
              alt: 'html5',
              label: 'HTML5'
            },
            {
              icon: css3,
              alt: 'css3',
              label: 'CSS3'
            },
            {
              icon: js,
              alt: 'js',
              label: 'JavaScript'
            },
            {
              icon: vscode,
              alt: 'vscode',
              label: 'Visual Studio Code'
            },
            {
              icon: postman,
              alt: 'postman',
              label: 'Postman'
            },
            {
              icon: react,
              alt: 'react',
              label: 'React JS'
            },
            {
              icon: sass,
              alt: 'sass',
              label: 'SASS'
            },
            {
              icon: nodejs,
              alt: 'nodejs',
              label: 'Node JS'
            },
            {
              icon: webpack,
              alt: 'webpack',
              label: 'Webpack'
            },
            {
              icon: redux,
              alt: 'redux',
              label: 'Redux'
            },
            {
              icon: git,
              alt: 'git',
              label: 'Git'
            },
            {
              icon: github,
              alt: 'github',
              label: 'Github'
            },
            {
              icon: npm,
              alt: 'npm',
              label: 'npm'
            },
            {
              icon: socket,
              alt: 'socket',
              label: 'socket.io'
            },
            {
              icon: webpack,
              alt: 'mongodb',
              label: 'MongoDB'
            }
          ]
        }
      ]
    },
    Requirements: {
      heading: 'Requirements',
      text: [
        'While we encourage all interested applicants to apply, to gain acceptance into our Bootcamp you must'
      ],
      list: [
        '- Be motivated and willing to learn and committed to creating a better future',
        '- Have good verbal and written communication skills',
        '- Bring your own laptop; (any Operating System)',
        '- Attend an in-person interview in order to determine fit',
        '- Successfully complete the remote section of the bootcamp (40 hours)'
      ],
      link: 'requirements'
    },
    Curriculum: {
      heading: 'Bootcamp Curriculum',
      text: [
        <span>
          Our syllabus is structured to{' '}
          <a
            href="https://we3academy.com/blog/development/how-bootcamps-boost-personality"
            target="_blank"
            rel="noreferrer noopener"
          >
            simulate a developer’s job environment
          </a>
          . You’ll be taught industry-relevant technologies and reinforce that
          knowledge by building applications &amp; completing daily programming
          projects.
        </span>
      ],
      link: 'curriculum',
      // cta: {
      //   text: [
      //     "Do you want to review the full syllabus before applying? Download it here"
      //   ],
      //   button: "Download Syllabus"
      // },
      timeline: [
        {
          title: 'Prep Week',
          duration: '1 Week',
          durationNum: 1,
          icon: curriculum1,
          breakdown: [
            {
              heading: 'WEEK 1',
              descriptor: 'REMOTE PREP WORK',
              text: [
                'After you enrol for the course, you’re given access to 40+ hours of online web development content on our student platform. This remote pre-work will help you setup the dev environment, walk you through the fundamentals of programming, Git, HTML CSS and Javascript.',
                'During this week, you will have access to our amazing Teaching Assistants on Slack or Email to clarify any doubt. There are quizzes and small assignments in this remote online module. Fully understanding this prep work is essential for our students to prepare themselves for the bootcamp. By the end of the course, you will be ready to take on the intense and fast pace of our on-campus learning!'
              ],
              summary: [
                'Programming Fundamentals',
                'Dev Environment Setup',
                'Basic Git Commands',
                'Beginner Level HTML & CSS',
                'How the Internet Works',
                'Current Frameworks & Languages'
              ]
            }
          ]
        },
        {
          title: 'Front End',
          duration: '5 Weeks',
          durationNum: 5,
          icon: curriculum2,
          breakdown: [
            {
              heading: 'WEEK 2',
              descriptor: 'FOUNDATION',
              text: [
                'In the first in-class week, we’ll go through orientation and introductions so you get to know your peers. We’ll cover advanced HTML5 and CSS topics and give you an introduction to web development and DOM manipulation. At the end of this week, you’ll build your first website - a simple portfolio website created with HTML and CSS.'
              ],
              summary: [
                'Advanced Web Concepts',
                'Introduction to Web Development',
                'CSS Animations',
                'Accessibility',
                'Forms & Validations',
                'Mini Project [ Portfolio Site ]'
              ]
            },
            {
              heading: 'WEEK 3',
              descriptor: 'INTERMEDIATE CSS & BASIC JS',
              // text: [],
              summary: [
                'CSS Frameworks - SASS',
                'Flex',
                'Bootstrap',
                'CSS Grids',
                'JS 101',
                'Expressions & Data Structures'
              ]
            },
            {
              heading: 'WEEK 4',
              descriptor: 'ALGORITHMS & DATA STRUCTURES',
              // text: [],
              summary: [
                'Whiteboarding',
                'Control Flow & Iteration',
                'Object Oriented JS',
                'Recursion',
                'Big O Notation',
                'JS Project - Game / Calculator'
              ]
            },
            {
              heading: 'WEEK 5',
              descriptor: 'INTRO TO REACT',
              // text: [],
              summary: [
                'Model View Controller',
                'Frontend Frameworks',
                'Intro to React JS',
                'React Router',
                'Webpack',
                'To-do App'
              ]
            },
            {
              heading: 'WEEK 6',
              descriptor: 'ADVANCED REACT & REDUX',
              // text: [],
              summary: [
                'State Management',
                'Redux',
                'Intro to Jest & Enzyme',
                'Test Driven Development',
                'Context API & Hooks',
                'Front End Project'
              ]
            }
          ]
        },
        {
          title: 'Back End',
          duration: '7 Weeks',
          durationNum: 6,
          icon: curriculum3,
          breakdown: [
            {
              heading: 'WEEK 7',
              descriptor: 'API IMPLEMENTATION & INTRO TO BACKEND'
              // text: [],
              // summary: []
            },
            {
              heading: 'WEEK 8',
              descriptor: 'CALLBACKS, PROMISES & GENERATORS'
              // text: [],
              // summary: []
            },
            {
              heading: 'WEEK 9',
              descriptor: 'NODE JS & EXPRESS'
              // text: [],
              // summary: []
            },
            {
              heading: 'WEEK 10',
              descriptor: 'CHAT APPLICATION WITH SOCKET.IO'
              // text: [],
              // summary: []
            },
            {
              heading: 'WEEK 11',
              descriptor: 'API CREATION'
              // text: [],
              // summary: []
            },
            {
              heading: 'WEEK 12',
              descriptor: 'TESTING & ERROR HANDLING'
              // text: [],
              // summary: []
            },
            {
              heading: 'WEEK 13',
              descriptor: 'AGILE & PROJECT MANAGEMENT'
              // text: [],
              // summary: []
            }
          ]
        },
        {
          title: 'Project',
          duration: '2 Weeks',
          durationNum: 2,
          icon: curriculum4,
          breakdown: [
            {
              heading: 'WEEK 14 & 15',
              descriptor: 'CAPSTONE PROJECT'
              // text: [],
              // summary: []
            }
          ]
        },
        {
          title: 'Career Week',
          duration: '1 Week',
          durationNum: 1,
          icon: curriculum5,
          breakdown: [
            {
              heading: 'WEEK 16',
              descriptor: 'CAREER WEEK',
              // text: [],
              summary: [
                'Career Counselling',
                'Resume Feedback',
                'Interview Preparation',
                'Test Driven Development',
                'Context API & Hooks',
                'Front End Project'
              ]
            }
          ]
        }
      ]
    },
    // "Daily Schedule": {
    //     heading: "Daily Schedule",
    //     link: "schedule"
    // },
    'Career Services': {
      heading: 'Career Services',
      link: 'career-services',
      text: [
        'We’ll help connect you with your first role as a developer after you graduate from our course. Our team will work with you to identify your unique career goals, and guide you to success.'
      ],
      list: [
        'CAREER COACHING',
        'RESUME PREP',
        'INTERVIEWS PREP',
        'NETWORKING',
        'ON-GOING SUPPORT',
        'EMPLOYER REACH'
      ]
    },
    // "Dates & Costs": {
    //     heading: "Dates & Costs",
    //     link: "dates-costs",
    //     // tuition: [
    //     //     { title: 'Full Tuition Fees', amount: '49500', currency: '₹' },
    //     //     { title: 'Monthly Tuition Fees', amount: '12375 / month', currency: '₹' }
    //     // ],
    //     schedule: {
    //         title: 'Next Course Begins',
    //         startDate: '02 June',
    //         endDate: '02 September',
    //         timings: 'Mon - Fri, 09:00 am - 06:00 pm'
    //     },
    //     disclaimer: (<p className="small-text text-light">* For more information contact <a className="text-light" href="mailto:admissions@we3academy.com">admissions@we3academy.com</a></p>)
    // },
    'Application Process': {
      heading: 'Application Process',
      link: 'process',
      process: [
        {
          heading: 'Online / Phone Application',
          text: [
            'Complete an online application covering your background, goals, and experience or give us a call.'
          ],
          button: {
            type: 'apply',
            customClass: 'btn btn-outline btn-outline-blue'
          }
        },
        {
          heading: 'Personal Interview',
          text: [
            "You’ll meet with an admission counsellor to discuss your application, tell you about our course structure and determine if it's a good fit."
          ]
        },
        {
          heading: 'Choose a Payment Plan',
          text: [
            'Choose to pay upfront for the entire course or in monthly instalments.'
          ]
        },
        {
          heading: 'Remote Prep Phase',
          text: [
            'Begin the course with a remote week of 40+ hours of supported self-paced learning covering JavaScript, GitHub, command line, HTML, and CSS.'
          ]
        },
        {
          heading: 'Course Begins',
          text: [
            'Your course begins. Meet your classmates and instructors and get started on your way to becoming a web developer.'
          ]
        }
      ]
    },
    FAQ: {
      heading: 'FAQ',
      link: 'faq',
      faq: [
        {
          question: 'When should I apply?',
          answer: [
            "We're accepting pre-registrations right now. The sooner you apply the better as our seats are filling in fast. We have a class size of max 20 students to keep an optimal student - instructor ratio."
          ]
        },
        {
          question: 'Do I need any programming skills or experience?',
          answer: [
            'No, you do not need to have programming skills or experience. However, you would be required to know the basics about using a computer, bring your own laptop and have the willingness to learn.'
          ]
        },
        {
          question:
            'Will I be able to create my own project after the bootcamp?',
          answer: [
            'Definitely! The course structure is designed to give you practical experience. You will be creating a clone application during the course and at the end of it create a capstone MVP project using your own ideas. This should give you enough exposure and support to build your own application.'
          ]
        }
      ],
      button: { type: 'faq' }
    }
  }
});

// const _designData = (type) => ({
//     heading: "UI / UX Design Bootcamp",
//     text: [`Become a UI / UX Designer in ${type=== 'full' ? '16' : '24'} weeks with our ${type}-time in-person training in Mumbai, India. We're still working on the course structure and looking for amazing instructors and mentors to deliver this right. Click below if you're interested in the course and want to be the first to know when it launches.`],
//     type: `${type.charAt(0).toUpperCase() + type.slice(1)} - Time`,
//     impInfo: "// COMING SOON.",
//     details: {
//     }
// });

const _contactData = {
  phone: '+91 98670 25257',
  email: 'info@we3academy.com',
  address:
    'Plot No. 169, Road No. 6, Abhinav Nagar, Borivali (E). Mumbai, India 400066'
};

const _hireData = {
  intro: {
    heading: 'Hire Our Students & Graduates',
    text: [
      'Need amazing dev talent? Tired of looking through resumes? Reach out to us and gain access to top tech talent. We select motivated individuals and train them with essential technical and workplace skills that helps them become great tech professionals.'
    ],
    img: introHireImg,
    link: '#hiringConnect',
    cta: 'Let’s Connect'
  },
  reasoning: {
    heading: 'Why should you hire from WE3 Academy?',
    grid: [
      {
        heading: 'Curated Candidates',
        image: hireCuratedCandidates,
        imageAlt: 'Curated Candidates',
        text: [
          'Our graduates know essential languages and platforms like JavaScript, HTML5, CSS, Git, React, and MongoDB. Most importantly, they understand how to think like developers.'
        ]
      },
      {
        heading: 'Quality Coding Skills',
        image: hireQualityCodingSkills,
        imageAlt: 'Quality Coding Skills',
        text: [
          'Our Instructors and Mentors are developers and hold students to high standards for quality. Our graduates have coded three complete projects & countless others on their GitHub accounts.'
        ]
      },
      {
        heading: 'Passion & Motivation',
        image: hirePassionAndMotivation,
        imageAlt: 'Passion & Motivation',
        text: [
          'Our students are passionate and curious by nature. We coach them to develop relevant technical & soft skills to help them in transitioning to an employment role.'
        ]
      },
      {
        heading: 'Career Services',
        image: hireCareerServices,
        imageAlt: 'Career Services',
        text: [
          'Our amazing team will help find you the perfect fit for your organisation’s needs and culture. We can help filter and find the right candidate for you.'
        ]
      }
    ]
  }
};

const _companyData = {
  intro: {
    heading: 'Bridging the gap between education and employability',
    text: [
      <span>
        We don’t offer you a “degree”. We get you a job. Our parent company{' '}
        <a style={{ color: 'var(--blue' }} href="https://we3solutions.in">
          WE3 Solutions
        </a>
        &nbsp; found it hard to hire new graduates and realised how a big gap
        exists between what is taught in colleges and what is expected in the
        industry. Our main goal with WE3 Academy is to bridge that gap and
        empower students to be able to build their own products.
      </span>
    ],
    img: introCompanyImg
  },
  reasoning: {
    heading: 'Our Core Values',
    grid: [
      {
        heading: 'Committed To Quality',
        image: companyCommittedToQuality,
        text: [
          'We set the bar for quality high and continuously look for new ways to improve on our services.'
        ]
      },
      {
        heading: 'Focussed on Results',
        image: companyFocussedOnResults,
        text: [
          'We are dedicated to delivering results. We want our students to succeed and be able to create their own products and we work hard to ensure that happens.'
        ]
      },
      {
        heading: 'Driven By Passion',
        image: companyDrivenByPassion,
        text: [
          'We’re driven by passion. After speaking with and collaborating with industry professionals who have been through similar experiences, we have made it our aim to improve education systems in India.'
        ]
      },
      {
        heading: 'Adaptable to Change',
        image: companyAdaptableToChange,
        text: [
          'It’s our job to foster knowledge, growth, and innovation. One of our core beliefs is that success requires getting out of the comfort zone and we’re constantly innovating and adapting.'
        ]
      }
    ]
  }
};

const _howWeWorkData = {
  intro: {
    heading: 'How We Work',
    text: [
      'We’re a career accelerator. WE3 Academy is where education meets a real-world professional network. We are striving to allow students to be hired right after they graduate and form a community of developers, mentors and students.'
    ],
    img: introHowWeWorkImg
  },
  reasoning: {
    heading: 'Our Process',
    grid: [
      {
        heading: 'Learn Practical Skills',
        image: howWeWork1,
        text: [
          'We integrate real-world projects into our courses and teach the current trends in the industry, so you’ll have actual projects to showcase once you graduate.'
        ]
      },
      {
        heading: 'Be A Part Of The Network',
        image: howWeWork2,
        text: [
          'You’ll begin expanding your professional network throughout and after your course. We’ll help you spend time at the right meetups and events where your future colleagues already hang out.'
        ]
      },
      {
        heading: 'Master The Interview',
        image: howWeWork3,
        text: [
          'Interviewing takes practice and too often candidates don’t prepare. At the end of each course, there is a career services week that will test your knowledge and your approach to problem solving.'
        ]
      },
      {
        heading: 'Get Hired',
        image: howWeWork4,
        text: [
          'Once you graduate you’ll be ready for the job hunt, and that’s when your Career Coach will help you out. We offer 6 months of career services after you graduate from our courses.'
        ]
      }
    ]
  },
  regularBlock: {
    heading: 'What sets us apart?',
    list: [
      {
        heading: 'Practical hands-on approach to learning',
        text: [
          'We believe in learning by doing. Our courses are structured to be 80% practical and 20% theoretical core concepts. We don’t conduct exams, the coursework is graded only on projects and presentations.'
        ]
      },
      {
        heading: 'Taught by developers & Guided by mentors',
        text: [
          'Our team of instructors and teaching assistants are themselves developers who have had practical experience in the industry. We call mentors periodically to give seminars or talks about various different topics.'
        ]
      },
      {
        heading: 'Real-world Projects',
        text: [
          'Our projects are based on real-world scenarios. We intend that a student will end up with at least 3 major projects and countless smaller ones on their github profile at the end of the course.'
        ]
      },
      {
        heading: 'Community & Support',
        text: [
          'We’ve built a community of developers and mentors so you’ll always have constant support, even after you graduate. You’ll get to meet like-minded developers in the course and many mentors who can become a part of your network.'
        ]
      }
    ]
  }
};

const _workWithUsData = {
  intro: {
    heading: 'Work With Us',
    text: [
      'We’re creating the next generation of developers, designers, and entrepreneurs. Come be a part of that change.'
    ],
    img: introWorkWithUsImg,
    cta: [
      {
        link: '#teach',
        btnText: 'Teach',
        style: 'btn btn-outline btn-outline-blue margin-right-15'
      },
      {
        link: '#partner',
        btnText: 'Partner',
        style: 'btn btn-filled btn-filled-blue'
      }
    ]
  },
  regularBlocks: [
    {
      id: 'helpBuild',
      heading: 'Help us build a better education',
      text: [
        'Compassion, relentless drive and contributing to the community and to give back are what makes us a tick. We value integrity, quality and are passionate about what we’re doing.',
        'Join a team of innovators and thinkers and help us bridge the gap between education and employability and to empower students with the capacity and tools to create amazing products.',
        "We provide equal employment opportunities to all employees and applicants. If you're talented and driven, please apply."
      ],
      blockPadding: '50px 0',
      bg: `var(--bg-light-gray`,
      breakout: true,
      textLight: true,
      textPaddingTop: 20
    },
    {
      id: 'instructors',
      sectionId: 'teach',
      heading: 'Instructors / Teaching Assistants',
      text: [
        'We’re always looking for people who are passionate about training the next generation of developers. If you have experience with programming and are passionate about teaching, apply to become an instructor. Don’t have a lot of experience teaching? Don’t worry, you can apply for our teaching assistant role.'
      ],
      textLight: true,
      h3Heading: true,
      cta: [
        {
          type: 'apply',
          customLink: '/apply/instructor',
          customText: 'Apply To Be An Instructor',
          customClass: 'btn btn-filled btn-filled-blue'
        }
      ]
    },
    {
      id: 'mentors',
      heading: 'Mentors',
      text: [
        <span>
          We’re looking for individuals who are{' '}
          <a
            href="https://we3academy.com/blog/development/effective-peer-mentor"
            target="_blank"
            rel="noreferrer noopener"
          >
            passionate about mentorship
          </a>
          &amp; education. If you believe in learning by doing and you love
          teaching almost as much as you love to code, come give a mentorship
          talk at WE3 Academy. We offer a flexible schedule that works around
          your current job / freelancing gigs / startup.
        </span>
      ],
      blockPadding: '40px 0 50px 0',
      textLight: true,
      h3Heading: true,
      cta: [
        {
          type: 'apply',
          customLink: '/apply/mentor',
          customText: 'Apply To Be A Mentor',
          customClass: 'btn btn-filled btn-filled-blue'
        }
      ]
    },
    {
      id: 'partners',
      sectionId: 'partner',
      heading: 'Partner / Invest',
      text: [
        'Like our cause and want to contribute? Let’s talk. Give us a call or email us and let’s work out how you can partner or invest for a better education.'
      ],
      blockPadding: '50px 0',
      breakout: true,
      textLight: false,
      h4Heading: true,
      bg: `var(--bg-light-blue)`,
      cta: [
        {
          type: 'email',
          customClass:
            'btn btn-outline btn-outline-blue margin-right-15 margin-top-5'
        },
        {
          type: 'call',
          customClass: 'btn btn-filled btn-filled-blue'
        }
      ]
    }
  ]
};

const _careerServicesData = {
  intro: {
    heading: 'Career Services',
    text: [
      'We provide career services to all our graduates. Our team will work with you to identify your unique career goals and help guide you to your first developer role.'
    ],
    img: introCareerServices,
    cta: [{ type: 'email', customText: 'Connect with an advisor' }]
  },
  reasoning: {
    heading: 'Services we provide',
    grid: [
      {
        heading: 'Career Guidance',
        image: careerCareerGuidance,
        text: [
          'Through one-on-one mentorship, we guide you about what options are out there and which to pursue based on your unique interests and goals.'
        ]
      },
      {
        heading: 'Job Search Preparation',
        image: careerJobSearchPreparation,
        text: [
          'We show you how to effectively search for a job, how to apply & communicate, build up an online presence, craft the perfect resume and master the personal and technical interviews.'
        ]
      },
      {
        heading: 'Networking',
        image: careerNetworking,
        text: [
          'We host events and periodically call mentors to our premises to give talks which will help expand your network.'
        ]
      },
      {
        heading: 'On-going Support',
        image: careerOnGoingSupport,
        text: [
          'Our team is here to support you when you need it. We help you with job applications, salary negotiation, mock interviews and finding connections & events.'
        ]
      }
    ]
  },
  cta: [{ type: 'viewCourses' }, { type: 'apply' }]
};

const _faqData = {
  heading: 'FAQ',
  faq: [
    {
      question: 'How does the admissions process work?',
      answer: [
        <span>
          Our application process begins with you either{' '}
          <a
            href="https://we3academy.com/apply"
            target="_blank"
            rel="noreferrer noopener"
          >
            registering online
          </a>{' '}
          / phone. After this we'll reach out to you and set up a personal
          interview. You'll meet with an admission councellor to discuss your
          application, go over the course structure, fees information and
          determine if it's a good fit. Once registered, you will be given
          access to our online portal, you can complete the remote prep week
          here before the course begins.
        </span>
      ]
    },
    {
      question: 'When should I apply?',
      answer: [
        "We're accepting pre-registrations right now. The sooner you apply the better as our seats are filling in fast. We have a class size of max 20 students to keep an optimal student - instructor ratio."
      ]
    },
    {
      question: 'Do I need any programming skills or experience?',
      answer: [
        'No, you do not need to have programming skills or experience. However, you would be required to know the basics about using a computer, bring your own laptop and have the willingness to learn.'
      ]
    },
    {
      question: 'Will I be able to create my own project after the bootcamp?',
      answer: [
        'Definitely! The course structure is designed to give you practical experience. You will be creating a clone application during the course and at the end of it create a capstone MVP project using your own ideas. This should give you enough exposure and support to build your own application.'
      ]
    },
    {
      question:
        'Can I learn all this online? Why should I opt for an in-class bootcamp?',
      answer: [
        "Yes, it's possible and we encourage our students to learn from different sources. However, for most of us, programming or UX/UI design can be difficult to learn without proper mentorship and guidance. We provide the right environment for learning and ensure you get the guidance you need from industry professionals who have been in your shoes. Sometimes it just takes someone with experience to point you in the right direction."
      ]
    },
    {
      question: 'What will happen after I graduate?',
      answer: [
        'Depends on your goals. If you want to get a job as a developer, we can help you make the right connections. If you are willing to build your project and launch a startup, we can help get you the right investors or resources. One thing we can assure is that you will be able to build an application from scratch!'
      ]
    }
  ],
  cta: [{ type: 'viewCourses' }, { type: 'apply' }]
};

const _smmData = (type) => ({
  heading: 'Social Media Marketing Course',
  img: smmImg,
  text: [
    `Master the art of Social Media Marketing in ${
      type === 'full' ? '5' : '5'
    } weekends with our in-person social media marketing course training in Mumbai, India.`
  ],
  type: `${type.charAt(0).toUpperCase() + type.slice(1)} - Time`,
  // impInfo: "// NEXT CLASS STARTS JAN 18TH, 2020",
  // impInfo: "// COMING SOON",
  details: {
    Overview: {
      link: 'overview',
      sections: [
        {
          heading: 'Overview',
          text: [
            <span>
              <a href="#curriculum">Social Media Marketing Course</a> at WE3
              Academy is designed to give you hands-on practical experience of
              running and managing social media accounts for your own or other
              businesses. We give you the skills, know-how, strategies and
              techniques to create and manage campaigns,{' '}
              <a
                href="https://we3academy.com/blog/digital-marketing/content-strategy-social-media"
                target="_blank"
                rel="noreferrer noopener"
              >
                develop content
              </a>{' '}
              and utilize each platform correctly to achieve organizational
              goals.
            </span>
          ],
          features: [
            { caption: '36+ Hours | 9 sessions' },
            { caption: `${type === 'full' ? '5' : '5'} Weekends` },
            { caption: 'Practical Experience' }
          ]
        },
        {
          heading: 'Who is this Course For?',
          details: [
            {
              heading: 'Recent Graduates / College Students',
              text: [
                'Recent Graduates / College Students looking forward to make a career in Social Media Marketing or want to get into Digital Marketing. This course will also be valuable to those who want to become social media influencers and manage their own or other social media accounts (business accounts).'
              ]
            },
            {
              heading: 'Working Professionals',
              text: [
                'Working professionals who want to upgrade their skills or switch career paths can also apply for the course. This course serves as a precursor to digital marketing and gives you the technical know-how about creating effective social media campaigns and managing multiple accounts.'
              ]
            },
            {
              heading: 'Entrepreneurs',
              text: [
                "Entrepreneurs looking to create or enhance their own businesses' social media presence. We walk you through different strategies and help you practically apply the skills you learnt to manage your posts, content and followers.  Analytics and trends show you essential information about your brand's perceived value."
              ]
            }
          ]
        },
        {
          heading: 'What will you learn?',
          icons: [
            {
              icon: instagram,
              alt: 'instagram',
              label: 'Instagram'
            },
            {
              icon: facebook,
              alt: 'facebook',
              label: 'Facebook'
            },
            {
              icon: twitter,
              alt: 'twitter',
              label: 'Twitter'
            },
            {
              icon: linkedin,
              alt: 'linkedin',
              label: 'Linkedin'
            },
            {
              icon: hootsuite,
              alt: 'hootsuite',
              label: 'Hootsuite'
            },
            {
              icon: buffer,
              alt: 'buffer',
              label: 'Buffer'
            },
            {
              icon: trello,
              alt: 'trello',
              label: 'Trello'
            },
            {
              icon: meetup,
              alt: 'meetup',
              label: 'Meetup'
            },
            {
              icon: canva,
              alt: 'canva',
              label: 'Canva'
            },
            {
              icon: unsplash,
              alt: 'unsplash',
              label: 'Unsplash'
            }
            // {
            //   icon: pixabay,
            //   alt: "pixabay",
            //   label: "Pixabay"
            // },
            // {
            //   icon: freepik,
            //   alt: "freepik",
            //   label: "Freepik"
            // }
          ]
        }
      ]
    },
    Requirements: {
      heading: 'Requirements',
      // text: [
      //   "While we encourage all interested applicants to apply, to gain acceptance into our Bootcamp you must"
      // ],
      list: [
        '- Be motivated and willing to learn and committed to creating a better future',
        '- Have good verbal and written communication skills',
        '- Bring your own laptop; (any Operating System)'
      ],
      link: 'requirements'
    },
    Curriculum: {
      heading: 'Course Outline',
      text: [
        'Our syllabus is structured to provide you with fundamental skills to handle the real work environment. You’ll be taught industry-relevant technologies and reinforce that knowledge by building social media profiles and managing social media campaigns for businesses or other professional accounts.'
      ],
      link: 'curriculum',
      // cta: {
      //   text: [
      //     "Do you want to review the full syllabus before applying? Download it here"
      //   ],
      //   button: "Download Syllabus"
      // },
      timeline: [
        {
          title: 'Weekend 1',
          duration: '2 Sessions',
          durationNum: 3,
          icon: curriculum1,
          breakdown: [
            {
              heading: 'SESSION 1',
              descriptor: 'INTRODUCTION & ORIENTATION',
              text: []
            },
            {
              heading: 'SESSION 2',
              descriptor: 'PROFILES & POST CREATION',
              text: []
            }
          ]
        },
        {
          title: 'Weekend 2',
          duration: '2 Sessions',
          durationNum: 3,
          icon: curriculum2,
          breakdown: [
            {
              heading: 'SESSION 3',
              descriptor: 'TRENDS + FACEBOOK AND INSTAGRAM',
              text: []
            },
            {
              heading: 'SESSION 4',
              descriptor: 'SCHEDULING TOOLS + LINKEDIN AND TWITTER',
              text: []
            }
          ]
        },
        {
          title: 'Weekend 3',
          duration: '2 Sessions',
          durationNum: 3,
          icon: curriculum2,
          breakdown: [
            {
              heading: 'SESSION 5',
              descriptor: 'CAMPAIGNS, AUDIENCE TARGETING & PROMOTIONS',
              text: []
            },
            {
              heading: 'SESSION 6',
              descriptor: 'CAMPAIGN SETUP PRACTICAL',
              text: []
            }
          ]
        },
        {
          title: 'Weekend 4',
          duration: '2 Sessions',
          durationNum: 3,
          icon: curriculum2,
          breakdown: [
            {
              heading: 'SESSION 7',
              descriptor: 'ANALYTICS & REPORTS',
              text: []
            },
            {
              heading: 'SESSION 8',
              descriptor: 'TWITTER PROMOTIONS & LINKEDIN CAMPAIGNS',
              text: []
            }
          ]
        },
        {
          title: 'Presentation Day',
          duration: '1 Session',
          durationNum: 1,
          icon: curriculum4,
          breakdown: [
            {
              heading: 'SESSION 9',
              descriptor: 'NETWORKING'
              // text: [],
              // summary: []
            }
          ]
        }
      ]
    },
    // "Daily Schedule": {
    //     heading: "Daily Schedule",
    //     link: "schedule"
    // },
    'Dates & Costs': {
      heading: 'Dates & Costs',
      link: 'dates-costs',
      tuition: [
        { title: 'Full Tuition Fees', amount: '15,000', currency: '₹' }
        // { title: 'Monthly Tuition Fees', amount: '12375 / month', currency: '₹' }
      ],
      schedule: {
        title: 'Next Course Begins',
        // startDate: '18 January, 2020',
        // endDate: '15 February, 2020',
        timings: 'Saturdays & Sundays, 2:00 pm - 6:00 pm'
      },
      disclaimer: (
        <p className="small-text text-light">
          * For more information contact{' '}
          <a className="text-light" href="mailto:admissions@we3academy.com">
            admissions@we3academy.com
          </a>
        </p>
      )
    },
    'Application Process': {
      heading: 'Application Process',
      link: 'process',
      process: [
        {
          heading: 'Online / Phone Application',
          text: [
            'Complete an online application covering your background, goals, and experience or give us a call.'
          ],
          button: {
            type: 'apply',
            customClass: 'btn btn-outline btn-outline-blue'
          }
        },
        {
          heading: 'Course Counselling Interview',
          text: [
            "You’ll meet with an admission counsellor to discuss your application, tell you about our course structure and determine if it's a good fit."
          ]
        },
        {
          heading: 'Choose a Payment Plan',
          text: [
            'Choose to pay upfront for the entire course or in monthly instalments.'
          ]
        },
        {
          heading: 'Course Begins',
          text: [
            'Your course begins. Meet your classmates and instructors and get started on with your career development course.'
          ]
        }
      ]
    },
    FAQ: {
      heading: 'FAQ',
      link: 'faq',
      faq: [
        {
          question: 'How does the admission process work?',
          answer: [
            <span>
              Our application process begins with you either{' '}
              <a
                href="https://we3academy.com/apply"
                target="_blank"
                rel="noreferrer noopener"
              >
                registering online
              </a>{' '}
              / phone. After this we'll reach out to you and set up a personal
              interview. You'll meet with an admission counselor to discuss your
              application, go over the course structure, fees information and
              determine if it's a good fit. Once registered, you will be given
              your batch details and few supplements.
            </span>
          ]
        },
        {
          question: 'When should I apply?',
          answer: [
            'You may apply at any time before the course start date. The sooner you apply the better as our seats are filling fast. We have a class size of max 20 students to keep an optimal student - instructor ratio.'
          ]
        },
        {
          question: 'Do I need any prior work experience?',
          answer: [
            'No, you do not need to have any experience. However, you would be required to know the basics about using a computer, bring your own laptop and willingness to learn.'
          ]
        }
      ],
      button: { type: 'faq' }
    }
  }
});

const _careerDevData = (type) => ({
  heading: 'Career Development Course',
  img: careerDevImg,
  text: [
    `Learn to Build Your Resume, Cover Letter & Improve Your Communication Skills ${
      type === 'full' ? '3' : '3'
    } weeks with our ${type}-time in-person course in Mumbai, India.`
  ],
  type: `${type.charAt(0).toUpperCase() + type.slice(1)} - Time`,
  // impInfo: "// NEXT CLASS STARTS JAN 13TH 2020",
  // impInfo: "// COMING SOON",
  details: {
    Overview: {
      link: 'overview',
      sections: [
        {
          heading: 'Overview',
          text: [
            <span>
              In this <a href="#curriculum">course</a>, we’ll help you{' '}
              <a
                href="https://we3academy.com/blog/career-development/outstanding-portfolio"
                target="_blank"
                rel="noreferrer noopener"
              >
                build an effective portfolio
              </a>
              , present your skills efficiently &amp; network effectively. With
              mock interviews and constant feedback, this course is designed to
              improve your communication &amp; presentation skills. The 1-on-1
              mock interviews and review sessions help in building your
              confidence and help you communicate better.
            </span>
          ],
          features: [
            { caption: '25+ Hours | 10 sessions' },
            { caption: `${type === 'full' ? '3' : '3'} Weeks` },
            { caption: 'Mentor-guided' }
          ]
        },
        {
          heading: 'Who is this Course for?',
          details: [
            {
              heading: 'Recent Graduates / College Students',
              text: [
                'Recent Graduates / College Students looking to improve their resume, cover letters, communication and networking skills. We help you prepare for interviews, apply to jobs, manage your social presence and give you guidelines for networking effectively.'
              ]
            },
            {
              heading: 'Working Professionals',
              text: [
                "Working professionals who want to upgrade their skills or switch career paths can also apply for the course. Interview prep and job search and application may help you land the job that you're looking to do."
              ]
            }
          ]
        },
        {
          heading: 'What will you learn?',
          text: [
            'Resume & Cover Letter Writing',
            'Job Search & Application',
            <a
              href="https://we3academy.com/blog/career-development/outstanding-portfolio"
              target="_blank"
              rel="noreferrer noopener"
            >
              Customizing LinkedIn Profile
            </a>,
            'Effective Email Communication',
            'Interview Preparation',
            'Networking & Presentation',
            'Workplace Etiquette'
          ],
          icons: []
        }
      ]
    },
    Requirements: {
      heading: 'Requirements',
      // text: [
      //   "While we encourage all interested applicants to apply, to gain acceptance into our Bootcamp you must"
      // ],
      list: [
        '- Be motivated and willing to learn and committed to creating a better future',
        '- Have good verbal and written communication skills',
        '- Bring your own laptop; (any Operating System)'
      ],
      link: 'requirements'
    },
    Curriculum: {
      heading: 'Course Outline',
      text: [
        'Our syllabus is structured to enable you to build your resume writing, email communication and interview skills. The 1-on-1 mock interview and review sessions help you overcome your communication barriers and help prepare you for interviews.'
      ],
      link: 'curriculum',
      // cta: {
      //   text: [
      //     "Do you want to review the full syllabus before applying? Download it here"
      //   ],
      //   button: "Download Syllabus"
      // },
      timeline: [
        {
          title: 'Week 1',
          duration: '3 SESSIONS',
          durationNum: 3,
          icon: curriculum1,
          breakdown: [
            {
              heading: 'DAY 1',
              descriptor: 'INTRODUCTION & ORIENTATION',
              text: []
            },
            {
              heading: 'DAY 2',
              descriptor: 'GOAL SETTING & COMPANY RESEARCH',
              text: []
            },
            {
              heading: 'DAY 3',
              descriptor: 'RESUME & COVER LETTER PREP',
              text: []
            }
          ]
        },
        {
          title: 'Week 2',
          duration: '3 Sessions',
          durationNum: 3,
          icon: curriculum2,
          breakdown: [
            {
              heading: 'DAY 4',
              descriptor: 'PROFILE CREATION & EMAIL COMMUNICATION',
              text: []
            },
            {
              heading: 'DAY 5',
              descriptor: '1-ON-1 RESUME REVIEW'
              // text: [],
            },
            {
              heading: 'DAY 6',
              descriptor: 'INTERVIEW PREP'
              // text: [],
            }
          ]
        },
        {
          title: 'WEEK 3',
          duration: '3 SESSIONS',
          durationNum: 3,
          icon: curriculum3,
          breakdown: [
            {
              heading: 'DAY 7',
              descriptor: '1-ON-1 MOCK INTERVIEW'
              // text: [],
            },
            {
              heading: 'DAY 8',
              descriptor: '1-ON-1 INTERVIEW REVIEW'
              // text: [],
            },
            {
              heading: 'DAY 9',
              descriptor: 'NETWORKING'
              // text: [],
            }
          ]
        },
        {
          title: 'Presentation Day',
          duration: '1 Session',
          durationNum: 1,
          icon: curriculum4,
          breakdown: [
            {
              heading: 'PRESENTATION DAY',
              descriptor: 'FINAL PRESENTATION & NETWORKING'
              // text: [],
            }
          ]
        }
      ]
    },
    // "Daily Schedule": {
    //     heading: "Daily Schedule",
    //     link: "schedule"
    // },
    'Dates & Costs': {
      heading: 'Dates & Costs',
      link: 'dates-costs',
      tuition: [
        { title: 'Full Tuition Fees', amount: '8500', currency: '₹' }
        // { title: 'Monthly Tuition Fees', amount: '12375 / month', currency: '₹' }
      ],
      schedule: {
        title: 'Next Course Begins',
        // startDate: '13 January, 2020',
        // endDate: '1 February, 2020',
        timings: 'Mon - Wed - Fri, 2:00 pm - 5:00 pm'
      },
      disclaimer: (
        <p className="small-text text-light">
          * For more information contact{' '}
          <a className="text-light" href="mailto:admissions@we3academy.com">
            admissions@we3academy.com
          </a>
        </p>
      )
    },
    'Application Process': {
      heading: 'Application Process',
      link: 'process',
      process: [
        {
          heading: 'Online / Phone Application',
          text: [
            'Complete an online application covering your background, goals, and experience or give us a call.'
          ],
          button: {
            type: 'apply',
            customClass: 'btn btn-outline btn-outline-blue'
          }
        },
        {
          heading: 'Course Counselling Interview',
          text: [
            "You’ll meet with an admission counsellor to discuss your application, tell you about our course structure and determine if it's a good fit."
          ]
        },
        {
          heading: 'Choose a Payment Plan',
          text: [
            'Choose to pay upfront for the entire course or in monthly instalments.'
          ]
        },
        {
          heading: 'Course Begins',
          text: [
            'Your course begins. Meet your classmates and instructors and get started on building your profile.'
          ]
        }
      ]
    },
    FAQ: {
      heading: 'FAQ',
      link: 'faq',
      faq: [
        {
          question: 'How does the admission process work?',
          answer: [
            <span>
              Our application process begins with you either{' '}
              <a
                href="https://we3academy.com/apply"
                target="_blank"
                rel="noreferrer noopener"
              >
                registering online
              </a>{' '}
              / phone. After this we'll reach out to you and set up a personal
              interview. You'll meet with an admission counselor to discuss your
              application, go over the course structure, fees information and
              determine if it's a good fit. Once registered, you will be given
              your batch details and few supplements.
            </span>
          ]
        },
        {
          question: 'When should I apply?',
          answer: [
            'You may apply for before a new batch starts. The sooner you apply the better as our seats are filling fast. We have a class size of max 20 students to keep an optimal student - instructor ratio.'
          ]
        }
      ],
      button: { type: 'faq' }
    }
  }
});

export {
  _homeData as homeData,
  _socialLinks as socialLinks,
  _socialBlock as socialBlock,
  _webDevData as webDevData,
  _careerDevData as careerDevData,
  _smmData as smmData,
  // _designData as designData,
  _ctaBlock as ctaBlock,
  _contactData as contactData,
  _hireData as hireData,
  _companyData as companyData,
  _howWeWorkData as howWeWorkData,
  _workWithUsData as workWithUsData,
  _careerServicesData as careerServicesData,
  _faqData as faqData
};
