import React from 'react';
import { socialLinks as defaultSocialLinks } from '../static/data';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

let SocialIcons = (props) => {
  let socialLinks = props?.socialLinks ?? defaultSocialLinks;
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: props.justifyContent,
        marginTop: '30px',
        ...props.customOuterStyles
      }}
    >
      {socialLinks.map((network, index) => (
        <a
          aria-label={network.name}
          key={index}
          href={network.link}
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: 'var(--text-light-gray)', ...props.customStyles }}
        >
          <FontAwesomeIcon icon={network.icon}></FontAwesomeIcon>
        </a>
      ))}
    </div>
  );
};

export default SocialIcons;
