import React, { lazy, Suspense } from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { Switch, Route } from 'react-router-dom';

// Shared
import NotFound404 from '../pages/NotFound404';
import Loading from '../components/Loading';

// Lazy
const Home = lazy(() => import('../pages/Home'));
const WebDevelopment = lazy(() => import('../pages/WebDevelopment'));
const CareerDevelopment = lazy(() => import('../pages/CareerDevelopment'));
const SocialMediaMarketing = lazy(() =>
  import('../pages/SocialMediaMarketing')
);
// import Design from '../pages/Design';

const Company = lazy(() => import('../pages/Company'));
const Hire = lazy(() => import('../pages/Hire'));
const HowWeWork = lazy(() => import('../pages/HowWeWork'));
const WorkWithUs = lazy(() => import('../pages/WorkWithUs'));
const CareerServices = lazy(() => import('../pages/CareerServices'));
const Blog = lazy(() => import('../pages/Blog'));
const Contact = lazy(() => import('../pages/Contact'));
const Application = lazy(() => import('../pages/Application'));
const ApplicationWorkshop = lazy(() => import('../pages/ApplicationWorkshop'));
const FAQ = lazy(() => import('../pages/FAQ'));
const Workshop = lazy(() => import('../pages/Workshop'));
const WorkshopSingle = lazy(() => import('../pages/WorkshopSingle'));
// const WorkshopLive = lazy(() => import('../pages/WorkshopLive'));
const WorkshopHighlight = lazy(() => import('../pages/WorkshopHighlight'));

const TermsOfService = lazy(() => import('../components/TermsOfService'));
const PrivacyPolicy = lazy(() => import('../components/PrivacyPolicy'));

function Body() {
  return (
    <Route
      render={({ location }) => (
        <div id="main">
          <TransitionGroup>
            <Suspense fallback={Loading}>
              <CSSTransition key={location.key} classNames="fade" timeout={300}>
                <Switch location={location}>
                  <Route exact path="/home" component={() => <Home />} />
                  <Route
                    exact
                    path="/privacy-policy"
                    component={() => <PrivacyPolicy />}
                  />
                  <Route
                    exact
                    path="/terms-of-service"
                    component={() => <TermsOfService />}
                  />
                  <Route
                    exact
                    path="/full-time/web-dev"
                    component={() => <WebDevelopment type="full" />}
                  />
                  {/* <Route exact path="/full-time/design" component={() => (<Design type="full" />)} /> */}
                  <Route
                    exact
                    path="/part-time/web-dev"
                    component={() => <WebDevelopment type="part" />}
                  />
                  {/* <Route exact path="/part-time/design" component={() => (<Design type="part" />)} /> */}
                  <Route
                    exact
                    path="/career-development-course"
                    component={() => <CareerDevelopment type="part" />}
                  />
                  <Route
                    exact
                    path="/social-media-marketing-course"
                    component={() => <SocialMediaMarketing type="part" />}
                  />

                  <Route exact path="/company" component={Company} />

                  <Route exact path="/hire" component={Hire} />
                  <Route exact path="/process" component={HowWeWork} />
                  <Route exact path="/work-with-us" component={WorkWithUs} />
                  <Route
                    exact
                    path="/career-services"
                    component={CareerServices}
                  />
                  <Route exact path="/blog" component={Blog} />
                  <Route exact path="/blog/:type" component={Blog} />
                  <Route exact path="/blog/:type/:id" component={Blog} />

                  <Route exact path="/workshop" component={Workshop} />
                  <Route
                    exact
                    path="/workshop/:id"
                    component={WorkshopSingle}
                  />
                  {/* 
                  <Route
                    exact
                    path="/workshop/live/:id"
                    component={WorkshopLive}
                  /> */}

                  <Route
                    exact
                    path="/workshop/highlight/:id"
                    component={WorkshopHighlight}
                  />

                  <Route exact path="/contact" component={Contact} />
                  <Route exact path="/faq" component={FAQ} />

                  <Route
                    exact
                    path="/apply-workshop"
                    component={ApplicationWorkshop}
                  />
                  <Route exact path="/apply" component={Application} />
                  <Route exact path="/apply/:type" component={Application} />

                  <Route exact path="/" component={() => <Home />} />
                  <Route component={NotFound404} />
                </Switch>
              </CSSTransition>
            </Suspense>
          </TransitionGroup>
        </div>
      )}
    />
  );
}

export default Body;
