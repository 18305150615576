import {
    CURR_PATH_CHANGED  
} from '../actions/types';
  
  export function formReducer(state = null, action) {
      switch (action.type) {
        case CURR_PATH_CHANGED:
            return action.payload ? { ...state, path: action.payload } : state
        default:
            return state;
      }
  }