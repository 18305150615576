import React from 'react';
import Helmet from 'react-helmet';

let SEO = (props) => {
  const {
    children,
    img,
    title,
    description,
    keywords,
    link,
    type = 'website'
  } = props;
  const url = `https://we3academy.com${link ? link : ''}`;
  return (
    <Helmet defaultTitle={title}>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta
        property="og:image"
        content={img ? img : 'https://we3academy.com/we3academy_social.png'}
      />
      {/* <meta property="og:url" content={url} /> */}
      <meta property="og:type" content={type} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta
        property="og:site_name"
        content={title ?? 'WE3 Academy | Coding Bootcamp in Mumbai'}
      />
      <meta name="twitter:image:alt" content="WE3 Academy" />

      <link rel="canonical" href={url} />
      {children}
    </Helmet>
  );
};

export default SEO;
